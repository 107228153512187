.table-responsive {
    overflow-x: auto;
  }
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  @media screen and (max-width: 767px) {
    table {
      width: 100%;
      max-width: 100%;
      overflow-x: scroll;
      display: block;
    }
    th {
      display: none;
    }
    td {
      display: block;
      text-align: right;
    }
    td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }